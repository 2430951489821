import {
  FREE_SERVICE_NAME,
  PRO_SERVICE_NAME,
  ENTERPRISE_SERVICE_NAME,
} from '@generaltranslation/settings/services';
import { FeatureGroup } from './PricingFeatureGrid';
import { useGT } from 'gt-next/client';
import pricing from '@generaltranslation/settings/pricing.js';

export function PricingData({
  activePlan,
}: {
  activePlan: 'monthly' | 'yearly';
}): FeatureGroup[] {
  const gt = useGT();
  // Restructure the features list into nested groups

  const denominator = 10000;
  const proValue = gt('First {tokens, number} tokens included', {
    variables: {
      tokens:
        pricing[PRO_SERVICE_NAME][activePlan === 'monthly' ? 'MONTH' : 'YEAR']
          .tokens_included,
    },
  });
  const proTooltip = gt(
    'Extra tokens are priced at {cost, currency} / {tokens, number}',
    {
      variables: {
        cost: pricing.translation_per_token * denominator,
        tokens: denominator,
      },
    }
  );

  const PRICING_FEATURES: FeatureGroup[] = [
    {
      id: 'core',
      name: gt('Core Features'),
      features: [
        {
          id: 'tokens',
          name: gt('Input tokens'),
          content: {
            [FREE_SERVICE_NAME]: {
              value: gt('Rate-limited'),
            },
            [PRO_SERVICE_NAME]: {
              value: proValue,
              tooltip: proTooltip,
            },
            [ENTERPRISE_SERVICE_NAME]: gt('Unlimited'),
          },
        },
        {
          id: 'locales',
          name: gt('Languages'),
          content: {
            [FREE_SERVICE_NAME]: gt('Unlimited'),
            [PRO_SERVICE_NAME]: gt('Unlimited'),
            [ENTERPRISE_SERVICE_NAME]: gt('Unlimited'),
          },
        },
        {
          id: 'on_demand',
          name: gt('On-Demand Translation'),
          content: {
            [FREE_SERVICE_NAME]: true,
            [PRO_SERVICE_NAME]: true,
            [ENTERPRISE_SERVICE_NAME]: true,
          },
        },
        {
          id: 'models',
          name: gt('State-of-the-art models'),
          content: {
            [FREE_SERVICE_NAME]: false,
            [PRO_SERVICE_NAME]: true,
            [ENTERPRISE_SERVICE_NAME]: true,
          },
        },
        {
          id: 'cdn',
          name: gt('Translation CDN'),
          content: {
            [FREE_SERVICE_NAME]: true,
            [PRO_SERVICE_NAME]: true,
            [ENTERPRISE_SERVICE_NAME]: true,
          },
        },
        {
          id: 'editor',
          name: gt('Translation Editor'),
          content: {
            [FREE_SERVICE_NAME]: false,
            [PRO_SERVICE_NAME]: true,
            [ENTERPRISE_SERVICE_NAME]: true,
          },
        },
        {
          id: 'nextjs',
          name: gt('Next.js SDK'),
          content: {
            [FREE_SERVICE_NAME]: true,
            [PRO_SERVICE_NAME]: true,
            [ENTERPRISE_SERVICE_NAME]: true,
          },
        },
        {
          id: 'react',
          name: gt('React SDK'),
          content: {
            [FREE_SERVICE_NAME]: true,
            [PRO_SERVICE_NAME]: true,
            [ENTERPRISE_SERVICE_NAME]: true,
          },
        },
      ],
    },
    {
      id: 'platform',
      name: gt('Platform'),
      features: [
        {
          id: 'users',
          name: gt('Users'),
          content: {
            [FREE_SERVICE_NAME]: gt('{n, number}', { variables: { n: 1 } }),
            [PRO_SERVICE_NAME]: gt('Unlimited'),
            [ENTERPRISE_SERVICE_NAME]: gt('Unlimited'),
          },
        },
        {
          id: 'projects',
          name: gt('Projects'),
          tooltip: gt('Additional projects can be purchased'),
          content: {
            [FREE_SERVICE_NAME]: gt('{n, number}', { variables: { n: 1 } }),
            [PRO_SERVICE_NAME]: gt('Unlimited'),
            [ENTERPRISE_SERVICE_NAME]: gt('Custom'),
          },
        },
      ],
    },
    {
      id: 'support',
      name: gt('Support'),
      features: [
        {
          id: 'github',
          name: gt('Help via GitHub'),
          content: {
            [FREE_SERVICE_NAME]: true,
            [PRO_SERVICE_NAME]: true,
            [ENTERPRISE_SERVICE_NAME]: true,
          },
        },
        {
          id: 'email',
          name: gt('Email Support'),
          content: {
            [FREE_SERVICE_NAME]: true,
            [PRO_SERVICE_NAME]: true,
            [ENTERPRISE_SERVICE_NAME]: true,
          },
        },
        {
          id: 'slack',
          name: gt('Slack Support'),
          content: {
            [FREE_SERVICE_NAME]: false,
            [PRO_SERVICE_NAME]: true,
            [ENTERPRISE_SERVICE_NAME]: true,
          },
        },
        {
          id: 'priority',
          name: gt('Priority Support'),
          content: {
            [FREE_SERVICE_NAME]: false,
            [PRO_SERVICE_NAME]: true,
            [ENTERPRISE_SERVICE_NAME]: true,
          },
        },
        {
          id: 'phone',
          name: gt('Phone Support'),
          content: {
            [FREE_SERVICE_NAME]: false,
            [PRO_SERVICE_NAME]: false,
            [ENTERPRISE_SERVICE_NAME]: true,
          },
        },
      ],
    },
    // More feature groups...
  ];
  return PRICING_FEATURES;
}
