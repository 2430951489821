// These values should be kept the same as the names of the services in the database

export const FREE_SERVICE_NAME = 'free_translation_v1';
export const PRO_SERVICE_NAME = 'pro_translation_v1';
export const ENTERPRISE_SERVICE_NAME = 'enterprise_translation_v1';

export const ADDITIONAL_PROJECTS_SERVICE_NAME = 'additional_projects';

export const SERVICE_NAMES = [
  FREE_SERVICE_NAME,
  PRO_SERVICE_NAME,
  ENTERPRISE_SERVICE_NAME,
  ADDITIONAL_PROJECTS_SERVICE_NAME,
];

export const TX_BATCH_SIZE = 150;
