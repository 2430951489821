'use client';
import React from 'react';
import { cn } from '@/lib/utils';
import { useState } from 'react';
import { motion } from 'framer-motion';
import AnimatedCustomButton from '@/components/animated/AnimatedCustomButton';
import { useRouter } from 'next/navigation';
import { T } from 'gt-next';
import {
  FREE_SERVICE_NAME,
  ENTERPRISE_SERVICE_NAME,
} from '@generaltranslation/settings/services.js';
import { useDict, useGT } from 'gt-next/client';
import { getPlanData } from './PricingFeatureGrid';
export function ClientPricingWithSwitch() {
  const [active, setActive] = useState<'monthly' | 'yearly'>('yearly');
  return <PricingWithSwitch active={active} setActive={setActive} />;
}

export default function PricingWithSwitch({
  active,
  setActive,
}: {
  active: 'monthly' | 'yearly';
  setActive: (active: 'monthly' | 'yearly') => void;
}) {
  return (
    <div>
      <T>
        <div className="flex flex-col gap-4 pt-10">
          <h2
            className={`max-lg:text-center mt-2 text-3xl sm:text-5xl flex gap-4 items-center justify-center flourish-text font-semibold pb-4 hero-text`}
            style={{ textWrap: 'pretty' }}
          >
            Plans
          </h2>
          <p
            className={
              'text-xl text-center mx-auto max-w-[800px] md:text-xl/relaxed lg:text-md/relaxed xl:text-xl/relaxed text-gray-500 dark:text-gray-400 pb-5'
            }
            style={{ textWrap: 'pretty' }}
          >
            Unlimited languages for free with our developer-friendly SDK
          </p>
        </div>
      </T>
      {/* <div className="h-10"></div> */}
      <PublicPricing active={active} setActive={setActive} />
    </div>
  );
}

export function PublicPricing({
  active,
  setActive,
}: {
  active: 'monthly' | 'yearly';
  setActive: (active: 'monthly' | 'yearly') => void;
}) {
  const d = useDict('dashboard.pricing');

  const gt = useGT();
  const planData = getPlanData(gt) as Record<string, any>;
  const tabs = [
    { name: gt('Monthly'), value: 'monthly' },
    { name: gt('Yearly'), value: 'yearly' },
  ];
  const router = useRouter();

  return (
    <div className="relative">
      {/* <div className="flex items-center justify-center bg-neutral-100 dark:bg-neutral-800  w-fit mx-auto mb-4 rounded-md overflow-hidden">
        {tabs.map((tab) => (
          <button
            key={tab.value}
            className={cn(
              'text-md font-medium p-4 rounded relative',
              active === tab.value ? 'text-white dark:text-black' : ''
            )}
            onClick={() => setActive(tab.value as 'monthly' | 'yearly')}
          >
            {active === tab.value && (
              <span className="absolute inset-0 flourish-background" />
            )}
            <span className="relative z-10">{tab.name}</span>
          </button>
        ))}
      </div> */}
      <div
        className={cn(
          'rounded-xl bg-background mx-auto md:mt-10 grid relative z-20 grid-cols-1 gap-4 items-center max-w-7xl',
          tiers.length === 2
            ? 'md:grid-cols-2'
            : 'md:grid-cols-2 lg:grid-cols-3'
        )}
      >
        {tiers.map((tier, tierIdx) => (
          <div
            key={tier.id}
            className={cn(
              tier.featured ? 'relative flourish-background shadow-2xl' : '',
              'rounded-lg px-4 py-6 sm:mx-4 lg:mx-0 h-full flex flex-col justify-between text-opposite border-rounded border'
            )}
          >
            <div>
              <h3
                id={tier.id}
                className={cn('leading-7 text-lg text-center hero-text')}
              >
                {d(`${tier.id}.name`)}
              </h3>
              <p className="mt-4 text-center">
                <motion.span
                  initial={{ y: 20, opacity: 0, filter: 'blur(10px)' }}
                  animate={{ y: 0, opacity: 1, filter: 'blur(0px)' }}
                  transition={{
                    duration: 0.2,
                    ease: 'easeOut',
                    delay: 0.1 * tierIdx,
                  }}
                  key={active}
                  className={cn(
                    'text-4xl font-bold tracking-tight  inline-block',
                    tier.featured
                      ? ''
                      : 'text-neutral-900 dark:text-neutral-200'
                  )}
                >
                  {active === 'monthly'
                    ? planData[tier.id].monthly
                    : planData[tier.id].yearly}
                </motion.span>
              </p>
              <p
                className={cn(
                  'mt-6 text-lg leading-7 h-12 md:h-12 xl:h-12 text-center'
                )}
              >
                {d(`${tier.id}.description`)}
              </p>
              <ul
                role="list"
                className={cn('mt-8 space-y-3 text-md leading-6 sm:mt-10')}
              >
                {planData[tier.id].features}
              </ul>
            </div>
            <div>
              <AnimatedCustomButton
                aria-describedby={tier.id}
                onClick={async () => {
                  router.push(tier.href);
                  return new Promise((resolve) => {
                    setTimeout(resolve, 3000);
                  });
                }}
                className={cn(
                  'flex items-center justify-center mt-8 w-full p-2 rounded font-medium',
                  tier.featured
                    ? ' bg-white text-black dark:bg-black dark:text-white dark:ring-black'
                    : 'flourish-background'
                )}
              >
                {d(`${tier.id}.cta`)}
              </AnimatedCustomButton>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export type Tier = {
  id: string;
  href: string;
  featured: boolean;
};

export const tiers: Tier[] = [
  {
    id: FREE_SERVICE_NAME,
    href: '/dashboard',
    featured: false,
  },
  // {
  //   id: PRO_SERVICE_NAME,
  //   href: '/dashboard/org/plans',
  //   featured: true,
  // },
  {
    id: ENTERPRISE_SERVICE_NAME,
    href: '/contact',
    featured: true,
  },
];
