'use client';

import {
  Check,
  X,
  HelpCircle,
  Languages,
  BookType,
  Edit,
  DatabaseZap,
  GitPullRequest,
  HardDrive,
  CircleHelp,
  Code,
  Users,
  Brain,
} from 'lucide-react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { Button } from '@/components/ui/button';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  TooltipProvider,
} from '@/components/ui/tooltip';
import { Tier } from '@/components/landing/pricing/Pricing';
import { Fragment, ReactNode, useMemo } from 'react';
import {
  ENTERPRISE_SERVICE_NAME,
  PRO_SERVICE_NAME,
} from '@generaltranslation/settings/services.js';
import { FREE_SERVICE_NAME } from '@generaltranslation/settings/services.js';
import { cn } from '@/lib/utils';
import { PricingData } from '@/components/landing/pricing/PricingData';
import { useDict, useGT } from 'gt-next/client';
import { Currency, T } from 'gt-next';
import pricing from '@generaltranslation/settings/pricing.js';

interface FeatureGridProps {
  activePlan: 'monthly' | 'yearly';
  tiers: Tier[];
}

// First, let's define our types
type FeatureContent = boolean | 'partial' | string | number | ReactNode;

interface Feature {
  id: string;
  name: string;
  tooltip?: string;
  content: {
    [tierId: string]:
      | {
          value: FeatureContent;
          tooltip?: string;
        }
      | FeatureContent;
  };
}

export interface FeatureGroup {
  id: string;
  name: string;
  features: Feature[];
}

export function getPlanData(gt: any) {
  const free_service_price = gt('Free', { context: "as in, the price of our free plan"});
  return {
    [FREE_SERVICE_NAME]: {
      monthly: free_service_price,
      yearly: free_service_price,
      features: (
        <T>
          <ul className="flex flex-col gap-4 font-medium">
            <li className="flex gap-x-3">
              <Users className={'h-6 w-5 flex-none'} aria-hidden="true" />1 User
            </li>
            <li className="flex gap-x-3">
              <Languages className={'h-6 w-5 flex-none'} aria-hidden="true" />
              Unlimited languages
            </li>
            <li className="flex gap-x-3">
              <DatabaseZap className={'h-6 w-5 flex-none'} aria-hidden="true" />
              Free translation CDN
            </li>
            <li className="flex gap-x-3">
              <Code className={'h-6 w-5 flex-none'} aria-hidden="true" />
              React and Next.js SDK
            </li>
            <li className="flex gap-x-3">
              <CircleHelp className={'h-6 w-5 flex-none'} aria-hidden="true" />
              Email support
            </li>
          </ul>
        </T>
      ),
    },
    [PRO_SERVICE_NAME]: {
      monthly: (
        <T>
          <Currency options={{ minimumFractionDigits: 0 }}>
            {pricing[PRO_SERVICE_NAME].MONTH.cost}
          </Currency>{' '}
          / month
        </T>
      ),
      yearly: (
        <T>
          <Currency options={{ minimumFractionDigits: 0 }}>
            {pricing[PRO_SERVICE_NAME].YEAR.cost / 12}
          </Currency>{' '}
          / month
        </T>
      ),
      features: (
        <T>
          <ul className="flex flex-col gap-4 font-medium">
            <li className="flex gap-x-3">
              <Users className={'h-6 w-5 flex-none'} aria-hidden="true" />
              Unlimited users
            </li>
            <li className="flex gap-x-3">
              <Languages className={'h-6 w-5 flex-none'} aria-hidden="true" />
              Unlimited languages
            </li>
            <li className="flex gap-x-3">
              <Brain className={'h-6 w-5 flex-none'} aria-hidden="true" />
              State-of-the-art models
            </li>
            <li className="flex gap-x-3">
              <DatabaseZap className={'h-6 w-5 flex-none'} aria-hidden="true" />
              Free translation CDN
            </li>
            <li className="flex gap-x-3">
              <Edit className={'h-6 w-5 flex-none'} aria-hidden="true" />
              Translation editor
            </li>
            <li className="flex gap-x-3">
              <CircleHelp className={'h-6 w-5 flex-none'} aria-hidden="true" />
              Priority support on email and Slack
            </li>
          </ul>
        </T>
      ),
    },
    [ENTERPRISE_SERVICE_NAME]: {
      monthly: gt('Contact us'),
      yearly: gt('Contact us'),
      features: (
        <T>
          <ul className="flex flex-col gap-4 font-medium">
            <li className="flex gap-x-3">
              <Languages className={'h-6 w-5 flex-none'} aria-hidden="true" />
              Unlimited languages
            </li>
            <li className="flex gap-x-3">
              <BookType className={'h-6 w-5 flex-none'} aria-hidden="true" />
              Unlimited translated tokens
            </li>
            <li className="flex gap-x-3">
              <DatabaseZap className={'h-6 w-5 flex-none'} aria-hidden="true" />
              Free translation CDN
            </li>
            <li className="flex gap-x-3">
              <Edit className={'h-6 w-5 flex-none'} aria-hidden="true" />
              Translation editor
            </li>
            <li className="flex gap-x-3">
              <GitPullRequest
                className={'h-6 w-5 flex-none'}
                aria-hidden="true"
              />
              Custom integrations
            </li>
            <li className="flex gap-x-3">
              <HardDrive className={'h-6 w-5 flex-none'} aria-hidden="true" />
              EU data residency
            </li>
            <li className="flex gap-x-3">
              <CircleHelp className={'h-6 w-5 flex-none'} aria-hidden="true" />
              24/7 support on email, phone, and Slack
            </li>
          </ul>
        </T>
      ),
    },
  } as const;
}

export function PricingFeatureGrid({ activePlan, tiers }: FeatureGridProps) {
  const d = useDict('client.pricing.features_grid') as any;

  const PRICING_FEATURES = useMemo(() => {
    return PricingData({ activePlan });
  }, [activePlan]);

  const renderFeatureContent = (content: Feature['content'][string]) => {
    const value =
      typeof content === 'object' && content !== null && 'value' in content
        ? content.value
        : content;
    const tooltip =
      typeof content === 'object' && content !== null && 'tooltip' in content
        ? content.tooltip
        : undefined;

    const renderedContent = (() => {
      if (value === true) {
        return <Check className="h-5 w-5 text-green-500 mx-auto" />;
      } else if (value === 'partial') {
        return <Check className="h-5 w-5 text-yellow-500 mx-auto" />;
      } else if (value === false) {
        return <X className="h-5 w-5 text-gray-300 mx-auto" />;
      } else if (value !== undefined) {
        return <span className="text-xs sm:text-sm">{value}</span>;
      } else {
        return <X className="h-5 w-5 text-gray-300 mx-auto" />;
      }
    })();

    return tooltip ? (
      <Tooltip>
        <TooltipTrigger>
          <div className="flex items-center gap-2">
            {renderedContent}
            <HelpCircle className="h-4 w-4 text-muted-foreground hover:text-foreground transition-colors" />
          </div>
        </TooltipTrigger>
        <TooltipContent className="text-sm">{tooltip}</TooltipContent>
      </Tooltip>
    ) : (
      renderedContent
    );
  };

  const gt = useGT();

  const planData = getPlanData(gt);

  return (
    <TooltipProvider>
      <div className="relative w-full max-w-[1200px] mx-auto overflow-x-auto sm:overflow-x-visible">
        <div className="min-w-[500px] md:min-w-0">
          <Table className="[&_tr]:border-0">
            <TableHeader className="[&_tr]:border-0">
              <TableRow className="border-0">
                <TableHead className="sm:max-w-none border-0 sticky left-0 sm:top-[64px] bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60 z-40 w-[100px] sm:w-[150px] md:w-[200px]"></TableHead>
                {tiers.map((tier) => (
                  <TableHead
                    key={tier.id}
                    className={cn(
                      'text-center p-0 border-0 sticky sm:top-[64px] bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60 z-40',
                      tier.id === ENTERPRISE_SERVICE_NAME &&
                        'hidden md:table-cell',
                      'max-w-[100px] sm:max-w-none'
                    )}
                  >
                    <div className="h-full px-1 pt-4 pb-2 sm:p-8 flex flex-col justify-between items-center gap-4 shadow-sm min-h-[220px]">
                      <div className="space-y-2">
                        <h3 className="text-base sm:text-xl font-semibold flourish-text tracking-tight">
                          {d(`${tier.id}.name`)}
                        </h3>
                        <div className="text-xl sm:text-3xl flourish-text font-bold">
                          {(planData as any)[tier.id][activePlan]}
                        </div>
                      </div>
                      <Button
                        variant={tier.featured ? 'default' : 'outline'}
                        className={cn(
                          'w-full',
                          tier.featured ? 'flourish-background' : ''
                        )}
                        onClick={() => (window.location.href = tier.href)}
                      >
                        {d(`${tier.id}.cta`)}
                      </Button>
                    </div>
                  </TableHead>
                ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {PRICING_FEATURES.map((group) => (
                <Fragment key={group.id}>
                  <TableRow className="border-0">
                    <TableCell
                      colSpan={tiers.length + 1}
                      className="sm:max-w-none font-semibold text-base border-0 bg-muted/50 left-0 w-[100px] sm:w-[150px] md:w-[200px]"
                    >
                      {group.name}
                    </TableCell>
                  </TableRow>
                  {group.features.map((feature) => (
                    <TableRow key={feature.id} className="border-0">
                      <TableCell className="font-medium border-0 left-0 bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60 w-[100px] sm:w-[150px] md:w-[200px]">
                        <div className="flex items-center gap-2 break-words">
                          <span className="text-xs sm:text-sm">
                            {feature.name}
                          </span>
                          {feature.tooltip && (
                            <Tooltip>
                              <TooltipTrigger>
                                <HelpCircle className="h-4 w-4 text-muted-foreground hover:text-foreground transition-colors" />
                              </TooltipTrigger>
                              <TooltipContent className="text-sm">
                                {feature.tooltip}
                              </TooltipContent>
                            </Tooltip>
                          )}
                        </div>
                      </TableCell>
                      {tiers.map((tier) => (
                        <TableCell
                          key={`${tier.id}-${feature.id}`}
                          className={cn(
                            'text-center text-xs sm:text-sm border-0 px-1 py-2 sm:p-4 break-words',
                            tier.id === ENTERPRISE_SERVICE_NAME &&
                              'hidden md:table-cell',
                            'max-w-[100px] sm:max-w-none'
                          )}
                        >
                          {renderFeatureContent(feature.content[tier.id])}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </Fragment>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </TooltipProvider>
  );
}
